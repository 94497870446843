@import './fonts.scss';

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
}

.react-icons {
    vertical-align: middle;
    margin-bottom: 6px;
}
