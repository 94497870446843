@import "../theme/colors.scss";

.sider {
    height: 100%;
}

.scrollContainer {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    // Firefox >= 64
    scrollbar-width: thin;
    scrollbar-color: $color-divider-dark;

    // Chrome/Safari
    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-divider-dark;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

.menu {
    border: 0;
}

.logo {
    height: 65px;
    line-height: 65px;
    margin: 0;
    text-align: center;
    vertical-align: middle;

    a {
        text-decoration: none;
    }

    .imgIcone {
        max-width: 200px;
        max-height: 60px;
    }
    
    .imgCollapsed {
        padding: 8px;
    }
}

.subMenu {
    :global {
        .ant-menu.ant-menu-sub.ant-menu-inline {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) inset;
        }
    }
}

.menuItemIcon {
    margin-right: 10px !important;
}
