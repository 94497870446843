
.container {
    height: 100%;
    overflow: hidden;
}

.layout {
    height: 100%;
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    overflow-y: auto;
}
